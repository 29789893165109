<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-07-11 13:06:35
 * @ Description: Fixer's create password page in landing section of the app.
 -->

<template>
    <div>
        <v-overlay
            v-model="isAuthenticating"
            color="rgb(34, 43, 69)"
            style="z-index: 1000;"
        >
            <v-row>
                <v-col
                    cols="12"
                    class="text-center"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                    >
                    </v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>

        <!-- Desktop -->
        <v-row
            v-if="$vuetify.breakpoint.lgAndUp"
            style="max-height: 100vh; background-color: rgb(249, 251, 253); position: relative;"
        >
            <!-- Close Button -->
            <v-btn
                icon
                style="position: absolute; right: 16px; top: 12px;"
                aria-label="close"
                @click="$router.push('/')"
            >
                <v-icon
                    color="black"
                    style="z-index: 10;"
                >
                    {{ mdiCloseThick }}
                </v-icon>
            </v-btn>
            <!-- Close Button -->

            <!-- Image -->
            <v-col
                cols="6"
                class="pa-0"
            >
                <v-img
                    src="/landing/signup/general/LandingSignupGeneralBackground_2.webp"
                    width="auto"
                    height="100vh"
                    max-height="100vh"
                />
            </v-col>
            <!-- Image -->

            <!-- Password Form -->
            <v-col
                cols="6"
                style="max-height: 100vh; overflow-y: auto; display: flex; justify-content: center;"
                align-self="center"
            >
                <v-form
                    v-model="validPasswordForm"
                    ref="passwordForm"
                >
                    <v-row
                        style="max-width: 555px;"
                    >
                        <v-col
                            cols="12"
                        >
                            <v-img
                                src="/branding/FixerLogoBlue.png"
                                width="170px"
                                class="mx-auto"
                                eager
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center os-29-sb"
                        >
                            Reset Password
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center os-14-r"
                        >
                            Your email has been verified. <br />
                            Please enter and confirm your new password.
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0 os-15-sb"
                        >
                            New Password<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="passwordRules"
                                v-model="password"
                                :append-icon="showPassword ? mdiEyeOffOutline : mdiEyeOutline"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0 os-15-sb"
                        >
                            Confirm Password<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="passwordRules"
                                v-model="confirmPassword"
                                :error-messages="passwordMatchError()"
                                :append-icon="showConfirmPassword ? mdiEyeOffOutline : mdiEyeOutline"
                                :type="showConfirmPassword ? 'text' : 'password'"
                                @click:append="showConfirmPassword = !showConfirmPassword"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center pt-0 os-13-sb"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="createPassword()"
                                :disabled="!validPasswordForm"
                            >
                                Confirm Password
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center os-14-sb"
                        >
                            Already a member? &nbsp; <span style="text-decoration: underline; cursor: pointer;">Sign In</span>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <!-- Password Form -->
        </v-row>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-row
            v-else-if="$vuetify.breakpoint.mdAndDown"
            style="min-height: 100vh; position: relative; background-color: rgb(249, 251, 253);"
            align="center"
            justify="center"
        >
            <!-- Close Button -->
            <v-btn
                icon
                style="position: absolute; right: 16px; top: 12px;"
                aria-label="close"
                @click="$router.push('/')"
            >
                <v-icon
                    color="black"
                    style="z-index: 10;"
                >
                    {{ mdiCloseThick }}
                </v-icon>
            </v-btn>
            <!-- Close Button -->

            <!-- Password Form -->
            <v-col
                cols="12"
                class="py-16"
                :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px'"
            >
                <v-form
                    v-model="validPasswordForm"
                    ref="passwordForm"
                >
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-img
                                src="/branding/FixerLogoBlue.png"
                                width="170px"
                                class="mx-auto"
                                eager
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center os-29-sb"
                        >
                            Reset Password
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center os-14-r"
                        >
                            Your email has been verified. <br />
                            Please enter and confirm your new password.
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0 os-15-sb"
                        >
                            New Password<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="passwordRules"
                                v-model="password"
                                :append-icon="showPassword ? mdiEyeOffOutline : mdiEyeOutline"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0 os-15-sb"
                        >
                            Confirm Password<span style="color: red;">*</span>
                            <br />
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="pt-2 os-13-r"
                                background-color="white"
                                :rules="passwordRules"
                                v-model="confirmPassword"
                                :error-messages="passwordMatchError()"
                                :append-icon="showConfirmPassword ? mdiEyeOffOutline : mdiEyeOutline"
                                :type="showConfirmPassword ? 'text' : 'password'"
                                @click:append="showConfirmPassword = !showConfirmPassword"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center pt-0 os-13-sb"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="createPassword()"
                                :disabled="!validPasswordForm"
                            >
                                Confirm Password
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <div
                    style="display: flex; justify-content: center; align-items: center; height: 55px;"
                    :style="$vuetify.breakpoint.md ? 'padding-top: 64px;' : 'padding-top: 32px;'"
                    class="os-14-sb"
                >
                    Already a member? &nbsp; <span style="text-decoration: underline; cursor: pointer;">Sign In</span>
                </div>
            </v-col>
            <!-- Password Form -->
        </v-row>
        <!-- Mobile -->
    </div>
</template>

<script>
    import { mdiCloseThick, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
    import { GeneralLoginController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'SignupGeneralCreatePasswordPage',

        computed: {
            passwordRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field'
                ]
            }
        },

        data () {
            return {
                mdiCloseThick,
                mdiEyeOutline,
                mdiEyeOffOutline,
                generalLoginController: null,
                email: '',
                password: '',
                confirmPassword: '',
                showPassword: false,
                showConfirmPassword: false,
                validPasswordForm: false,
                isAuthenticating: true
            }
        },

        async beforeMount () {
            this.generalLoginController = new GeneralLoginController()
        },

        mounted () {
            this.isAuthenticating = false
        },

        methods: {
            setLoggedInUser (response) {
                this.$http.token = response.token

                // delete response.token
                this.$store.commit('user/setUserData', response)
                this.$store.commit('setNewUser', false)
            },

            passwordMatchError () {
                if (this.confirmPassword.replace(/\s/g, '') !== '') {
                    return (this.password === this.confirmPassword) ? '' : 'Passwords must match'
                } else return ''
            },

            async createPassword () {
                try {
                    await this.generalLoginController.resetPassword(this.$route.params.token, this.password)

                    this.$store.commit('setSnackbar', {
                        text: 'Password Reset',
                        show: true,
                        color: '#069B34'
                    })

                    this.$store.commit('setLoginOverlay', true)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
<style>
    @import url('../../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
<style scoped>
    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
